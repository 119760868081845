var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page-list-training" },
    [
      _c(
        "div",
        { staticClass: "page-list-training__head mb-4" },
        [
          _c("h1", { staticClass: "title mb-0 page-list-training__title" }, [
            _vm._v(_vm._s(_vm.$t("lk.header.listOfCourses"))),
          ]),
          _c(
            "CButton",
            {
              staticClass: "cbtn",
              attrs: { color: "primary" },
              on: { click: _vm.show },
            },
            [_vm._v(" " + _vm._s(_vm.$t("lk.training.btnCreateTitle")) + " ")]
          ),
        ],
        1
      ),
      _c("list-training", {
        attrs: { to: "item-training", onlyControl: true },
      }),
      _c(
        "CModal",
        {
          attrs: {
            title: _vm.$t("lk.training.btnCreateTitle"),
            centered: "",
            show: _vm.showPopup,
            closeOnBackdrop: false,
          },
          on: { "update:show": _vm.close },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function () {
                return [
                  _c(
                    "CLoadingButtonCustom",
                    {
                      staticClass: "cbtn mt-4",
                      attrs: {
                        color: "primary",
                        loading: _vm.loadBtn,
                        disabled: _vm.loadBtn,
                      },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.createCourse.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("lk.training.btnCreateCourse")) +
                          " "
                      ),
                    ]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("CInput", {
            staticClass: "pl-0",
            attrs: { label: _vm.$t("lk.training.nameTraining"), type: "text" },
            model: {
              value: _vm.courseName,
              callback: function ($$v) {
                _vm.courseName = typeof $$v === "string" ? $$v.trim() : $$v
              },
              expression: "courseName",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }