<template>
  <div class="page-list-training">
    <div class="page-list-training__head mb-4">
      <h1 class="title mb-0 page-list-training__title">{{ $t('lk.header.listOfCourses') }}</h1>
      <CButton
        class="cbtn"
        color="primary"
        @click="show"
      >
        {{ $t('lk.training.btnCreateTitle') }}
      </CButton>
    </div>
    <list-training :to="'item-training'" :onlyControl="true"></list-training>
    <CModal
      :title="$t('lk.training.btnCreateTitle')"
      centered
      :show="showPopup"
      :closeOnBackdrop="false"
      @update:show="close"
    >
      <CInput
        :label="$t('lk.training.nameTraining')"
        type="text"
        class="pl-0"
        v-model.trim="courseName"
      />
      <template v-slot:footer>
        <CLoadingButtonCustom
          color="primary"
          class="cbtn mt-4"
          :loading="loadBtn"
          :disabled="loadBtn"
          @click.native="createCourse"
        >
          {{ $t('lk.training.btnCreateCourse') }}
        </CLoadingButtonCustom>
      </template>
    </CModal>
  </div>
</template>

<script>
import axios from 'axios'
import ListTraining from "@/components/training/ListTraining";

export default {
  name: "PageListTraining",
  components: {
    ListTraining,
  },
  data() {
    return {
      baseUrl: axios.defaults.baseURL,
      showPopup: false,
      courseName: '',
      loadBtn: false,
    }
  },
  methods: {
    createCourse() {
      if (this.courseName === '') {
        this.$toastr.error(this.$t('lk.training.errorNameCourse'))
        return
      }

      this.loadBtn = true;

      axios.post(`${this.baseUrl}/api/v2/courses`, {
        course_title: this.courseName,
      })
        .then(res => {
          const data = res?.data?.data

          this.$router.push({ name: 'item-training', params: { id: data.id, slug: data.slug || data.id }})
        })
        .catch((e) => {
          this.$toastr.error(this.$t('lk.training.errorCreateCourse'))
        })
        .finally(() => {
          this.loadBtn = false
        })
    },
    show() {
      this.showPopup = true
    },
    close() {
      this.showPopup = false
    },
  },
};
</script>

<style lang="scss" scoped>
.page-list-training {
  &__head {
    @include media-breakpoint-up(md) {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
  &__title {
    flex: 1 1 auto;
    width: 100%;
  }
  .btn {
    flex: none;
    width: 100%;
    @include media-breakpoint-up(md) {
      width: fit-content;
    }
  }
  &__title + .btn {
    margin-top: 0.625rem;
    @include media-breakpoint-up(md) {
      margin-left: 1.5rem;
      margin-top: 0;
    }
  }
}
</style>
